<template>
  <div>
    <!-- Table Container Card -->
    <user-password-edit :user-info="userInfo" />
    <b-card no-body class="mb-0">
      <div class="m-2">
        <modal-details-remark
          :remark-data.sync="remarkData"
          @refetch-data="refetchData"
          @hide-remarks="hideRemarks"
        />
        <!-- Table Top -->

        <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
          <h3 class="font-weight-bolder text-nowrap mb-0">User</h3>
          <div class="form-col-select ml-2">
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <feather-icon size="18" icon="ChevronDownIcon" />
          </div>
          <div class="search-primary w-100">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input v-model="searchQuery" class="d-inline-block" placeholder="Find User" />
          </div>
          <b-button variant="primary" class="px-3 mobile-w100" :to="{ name: 'users-create' }"
            v-if="ability.can('create', 'user')">
            <span class="text-nowrap"><feather-icon size="14" icon="PlusIcon" /> User</span>
          </b-button>
        </div>

        <div class="d-flex justify-content-between">
          <div class="d-flex mt-2 mt-sm-1 flex-wrap flex-sm-nowrap">
            <b-button class="mr-2" variant="primary" @click="statusFilter = null">
              <feather-icon icon="AlignJustifyIcon" class="mr-50" />
              All
            </b-button>
            <b-button class="mr-2 mt-1 mt-sm-0" variant="primary" @click="statusFilter = '1'">
              <feather-icon icon="BookOpenIcon" class="mr-50" />
              Active
            </b-button>
            <b-button class="mr-2 mt-1 mt-sm-0" variant="primary" @click="statusFilter = '2'">
              <feather-icon icon="LoaderIcon" class="mr-50" />
              Inactive
            </b-button>
            <b-button class="mr-2 mt-1 mt-sm-0" variant="primary" @click="statusFilter = '3'">
              <feather-icon icon="CheckIcon" class="mr-50" />
              Resigned
            </b-button>
            <b-button class="mr-2 mt-1 mt-sm-0" variant="primary" @click="statusFilter = '4'">
              <feather-icon icon="XIcon" class="mr-50" />
              Dismissal
            </b-button>
            <b-button class="mr-2 mt-1 mt-sm-0" variant="primary" @click="statusFilter = '5'">
              <feather-icon icon="XIcon" class="mr-50" />
              Retired
            </b-button>
            <b-button class="mt-1 mt-sm-0" variant="primary" @click="statusFilter = '6'">
              <feather-icon icon="XIcon" class="mr-50" />
              Terminated
            </b-button>
          </div>

          <div class="d-flex mt-2">
            <div class="form-col-select ml-2">
              <v-select v-model="isClientFilter" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="isForClientFilter" :clearable="false" class="per-page-selector d-inline-block mx-50"
                style="width:120px;" />
              <feather-icon size="18" icon="ChevronDownIcon" />
            </div>

            <div>
              <!-- <v-select v-model="isClientFilter" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="isForClientFilter"
                :clearable="false" class="" /> -->
              <b-overlay :show="show" rounded="sm" spinner-variant="primary">
                <b-button v-on:click="onBtnExport()" class="btn-primary-green mobile-w100">
                  <feather-icon icon="FileTextIcon" class="mr-50" size="16" />
                  Export
                </b-button>
              </b-overlay>
            </div>
          </div>
        </div>
      </div>

      <b-modal id="remarks-modal" centered size="lg" hide-footer title="User Remarks">
        <b-overlay :show="show" rounded="sm">
          <app-timeline class="m-2">
            <template v-if="remarks.length > 0">
              <app-timeline-item v-for="(remark, index) in remarks" :key="`user_remark_${remark.id}_${index}`">
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h6>Remarks by: {{ remark.create_user.name }}</h6>
                  <small class="text-muted">{{ remark.date }}</small>
                </div>
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <div v-if="!editRemarkBoolean">
                    <p>{{ remark.remarks }}</p>
                  </div>
                  <div v-else>
                    <b-form-input v-model="remark.remarks" id="remarks" style="width: 300px;" />
                  </div>
                </div>
              </app-timeline-item>
            </template>
          </app-timeline>
        </b-overlay>
      </b-modal>

      <b-modal id="add-remarks" centered size="lg" hide-footer title="Add User Remarks">
        <b-row class="m-3">
          <b-col cols="12">
            <b-form-group label="Remarks" :label-for="`remarks`" label-cols-md="3" class="required">
              <b-form-input :id="`remarks`" v-model="newRemark.remarks" placeholder="Enter Remarks" />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="modal-bottom">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="addRemarksHide" variant="outline-danger"
            class="mr-1">
            Cancel
          </b-button>
          <!-- @click="submitModal" -->
          <b-button type="submit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1"
            @click="addNewRemarks">
            Submit
          </b-button>
        </div>
      </b-modal>


      <b-table ref="refUserListTable" class="position-relative" :items="fetchUsers" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc">
        <template #cell(name)="data">
          <div v-if="data.item.name != null">
            {{ data.item.name }}<br />
           <span v-if="userData.id == 1 && false">
            <b-badge v-for="team in data.item.teams" v-bind:style="{
      'background-color': team.background_color,
      color: team.font_color,
    }" :key="`team_${team.id}`">{{ team.en_name }}
            </b-badge>
            </span>
          </div>
        </template>
        <template #cell(name_cn)="data">
          <div v-if="data.item.name_cn == null">-</div>
          <div v-else>{{ data.item.name_cn }}</div>
        </template>
        <template #cell(working_visa)="data">
          <div v-if="data.item.working_visa == 0">N/A</div>
          <div v-else>Yes</div>
        </template>
        <template #cell(license_number)="data">
          <div v-if="data.item.user_licenses.length > 0">
            <div v-for="license in data.item.user_licenses" :key="`license_number_${license.id}`">
              {{ license.license_number }} ({{ license.authority_name }})
            </div>
          </div>
          <div v-else>N/A</div>
        </template>
        <template #cell(annual_return)="data">
          <div v-if="data.item.user_licenses.length > 0">
            <p v-for="license in data.item.user_licenses" :key="`license_return_${license.id}`">
              {{ license.annual_return }} - {{ license.authority_name }}
            </p>
          </div>
          <div v-else>N/A</div>
        </template>
        <template #cell(license_types)="data">
          <div v-if="data.item.user_licenses.length > 0">
            <div v-for="type in data.item.license_types" :key="`license_types_${type.id}`">
              {{ type.license_type }}
            </div>
          </div>
          <div v-else>N/A</div>
        </template>



        <template #cell(user_statuses_id)="data">
          <div v-if="data.item.user_statuses_id == 1" size="18" class="mr-50 text-success">
            Active
          </div>
          <div v-if="data.item.user_statuses_id == 2" size="18" class="mr-50 text-secondary">
            Inactive
          </div>
          <div v-if="data.item.user_statuses_id == 3" size="18" class="mr-50 text-warning">
            Resigned
          </div>
          <div v-if="data.item.user_statuses_id == 4" size="18" class="mr-50 text-danger">
            Dismissal
          </div>
          <div v-if="data.item.user_statuses_id == 5" size="18" class="mr-50 text-info">
            Retired
          </div>
          <div v-if="data.item.user_statuses_id == 6" size="18" class="mr-50 text-dark">
            Terminated
          </div>
        </template>

        <template #cell(remarks)="data">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            v-if="data.item.user_remark.length > 0"
            @click="openModel(data.item.user_remark)"
          >
            <feather-icon icon="AlertCircleIcon" />
          </b-button>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown class="action-trigger-btn" :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="EyeIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item :to="{ name: 'users-view', params: { id: data.item.id } }"
              v-if="ability.can('read', 'user')">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="ability.can('update', 'user')"
              :to="{ name: 'users-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="ability.can('update', 'user')" @click="triggerResetPasswordModal(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Reset Password</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="ability.can('delete', 'user')" @click="handleDelete(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="ability.can('update', 'user')" @click="openModalDetailsRemark(data.item)">
              <feather-icon icon="PlusIcon" />
              <span class="align-middle ml-50">Manage Remarks</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalUsers" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBModal,
  BFormGroup,
  BForm,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useUsersList from "./useUsersList";
import userStoreModule from "../userStoreModule";
import UserListAddNew from "./UserListAddNew.vue";
import UserPasswordEdit from "./UserPasswordEdit.vue";
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import ModalDetailsRemark from "../modal/ModalDetailsRemark.vue";

export default {
  components: {
    UserListAddNew,
    UserPasswordEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    AppTimeline,
    AppTimelineItem,
    VBModal,
    BFormGroup,
    BForm,
    BOverlay,
    ModalDetailsRemark,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  methods: {
    addRemarksShow(user_id) {
      this.newRemark.user_id = user_id;
      this.$bvModal.show("add-remarks");
    },
    addRemarksHide() {
      this.$bvModal.hide("add-remarks");
    },
    deleteRemark(id) {
      this.show = true;
      store
        .dispatch("app-user/deleteRemark", {id:id})
        .then((response) => {
          this.show = false;
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.refetchData();
          this.editRemarkBoolean = false;
          this.$bvModal.hide("remarks-modal");
        })
        .catch((error) => {
          this.show = false;
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
     openModalDetailsRemark(item) {
      this.remarkData = { ...item };
      this.$bvModal.show("modal-details-remark");
    },
    hideRemarks() {
      this.$bvModal.hide("modal-details-remark");
    },
    confirmEdit(remark) {
      this.show = true;
      this.editRemarkBoolean = false;
      store
        .dispatch("app-user/addNewRemark", remark)
        .then((response) => {
          this.show = false;
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.refetchData();
          this.editRemarkBoolean = false;
        })
        .catch((error) => {
          this.show = false;
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    editRemark() {
      this.editRemarkBoolean = true;
    },
    addNewRemarks() {
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-user/addNewRemark", this.newRemark)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
              this.addRemarksHide();
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    openModel(remark) {
      this.remarks = remark;
      this.$bvModal.show("remarks-modal");
    },
    hideModal() {
      console.log('remarks clicked');
      this.$bvModal.hide("remarks-modal");
    },
    onBtnExport() {
      console.log('user export')
      this.show = true // Add this line to show overlay
      store
        .dispatch('app-user/fetchUsersExport')
        .then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);
          // create "a" HTLM element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `Users Export List`);
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          this.show = false // Add this line to hide overlay
        })
        .catch((error) => {
          this.show = false // Add this line to hide overlay on error
          console.error('Export failed:', error)
        });
    },
    triggerResetPasswordModal(item) {
      console.log("triggerResetPasswordModal", item);
      this.userInfo.id = item.id;
      this.userInfo.name = item.name;
      this.userInfo.password = "";
      this.userInfo.password_confirmation = "";
      this.$bvModal.show("modal-reset-user-password");
    },
    handleDelete(id) {
      this.$swal({
        title: "Are you sure?",
        text:
          "Your action is final and you will not be able to retrieve the user record.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-user/deleteUser", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.selectBankAc = "";
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      userInfo: { id: 0, password: "", password_confirmation: "" },
      remarks: [],
      newRemark: {
        user_id: null,
        remarks: null,
      },
      editRemarkBoolean: false,
      show: false,
      remarkData: {},
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      isForClientFilter,
      isClientFilter,
      ability,
    } = useUsersList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      ability,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      isForClientFilter,
      isClientFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
